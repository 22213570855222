<template lang="pug">
.organization-admin-list
  .d-flex.justify-between.align-center
    .text-gray-900.text-2xl Organization Admins
    div
      v-btn(
        color="primary"
        depressed
        @click="isOrgAdminCreateEditOpen = true"
      ) + New Admin

  .d-flex.justify-between.mt-16.gap-8
    div.flex-1
      v-text-field(
        class="mb-4"
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        clearable
      )

    .flex-1
      v-select(
        v-model="status"
        outlined
        multiple
        dense
        :items="statuses"
      )

  v-data-table.border.shadow.cursor-pointer(
    must-sort
    :items="users"
    :loading="isPending"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :headers="headers"
    :options.sync="options"
    no-data-text="No organizations found"
    @click:row="goToOrganization"
  )
    template(
      v-slot:item.organizationId="{ item: user }"
    )
      div {{ user.organization ? user.organization.name : 'Not set up' }}
    template(
      v-slot:item.status="{ item: user }"
    )
      v-chip(
        :color="`${ user.statusColor } dark-3`"
        :text-color="`${ user.statusColor } lighten-5`"
        small
      )
        span {{ user.status }}
    template(
      v-slot:item.actions="{ item: user }"
    )
      v-menu( left offset-y )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            color="gray"
            v-bind="attrs"
            v-on="on"
            depressed
            small
            fab
          )
            v-icon mdi-dots-vertical
        v-list.divide-y
          v-list-item(
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'orgAdmin')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-square-edit-outline
            v-list-item-content
              v-list-item-title.cursor-pointer Edit

          v-list-item(
            v-if="$can('manage', 'all')"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openSuperAdminExport(user.organizationId)"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-download
            v-list-item-content
              v-list-item-title.cursor-pointer Export

          v-list-item(
            v-if="user.status === 'active' || user.status === 'new'"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'confirmDeactivate')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-account-cancel
            v-list-item-content
              v-list-item-title.cursor-pointer Deactivate

  OrgAdminCreateEditDialog(
    :isOpen="isOrgAdminCreateEditOpen"
    @close="closeAndRefresh"
    :editingUser="editingUser"
    v-if="isOrgAdminCreateEditOpen"
  )

  ConfirmDialog(
    :title="confirmContext.title"
    :text="confirmContext.text"
    :actionText="confirmContext.actionText"
    :type="confirmContext.type"
    :isOpen="isConfirmDialogOpen"
    @close="closeAndMaybeUpdateUser"
    v-if="isConfirmDialogOpen"
  )
</template>

<script>
import SuperAdminExportDialog from '@/components/Dialogs/SuperAdminExport.Dialog'
import { ref, computed } from '@vue/composition-api'
import OrgAdminCreateEditDialog from '@/views/users/OrgAdmin.CreateEdit.Dialog'
import ConfirmDialog from '@/components/Dialogs/Confirm.Dialog'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'

export default {
  name: 'OrgAdminList',

  components: {
    OrgAdminCreateEditDialog,
    ConfirmDialog,
    SuperAdminExportDialog
  },

  methods: {
    openDialog(data, dialog) {
      switch (dialog) {
        case 'orgAdmin': {
          this.isOrgAdminCreateEditOpen = true
          this.editingUser = data
          break
        }
        case 'confirmDeactivate': {
          this.editingUser = data
          this.confirmContext = {
            title: "Deactivate User",
            text: `Are you sure you want to deactivate ${ this.editingUser.fullName }?`,
            actionText: "Deactivate",
            type: "warning"
          }

          this.isConfirmDialogOpen = true
          break
        }
        default: {
          console.error('Invalid method name')
          break;
        }
      }

    },
    closeAndRefresh(refresh) {
      if (refresh) this.findUsers()
      this.isOrgAdminCreateEditOpen = false;
      this.editingUser = null;
    },
    async closeAndMaybeUpdateUser(shouldDeactivate) {
      if (shouldDeactivate) {
        const { id } = this.editingUser
        const { User } = this.$FeathersVuex.api
        /*  the only action that "activates" a user is when they create
            their organization; new in this case sends out the email */
        const newUser = new User({ id, status: 'inactive' })

        try {
          await newUser.patch({ data: { status: 'inactive' } })
          this.$snackSuccess(`Successfully deactivated ${ this.editingUser.firstName }`)
        } catch(e) {
          this.$snackError(e)
        }
      }

      this.isConfirmDialogOpen = false
    }
  },

  setup(props, { root: { $router, $dialog } }) {
    const editingUser = ref(null)
    const isOrgAdminCreateEditOpen = ref(false)
    const isConfirmDialogOpen = ref(false)

    const goToOrganization = ({ id, organization }) => {
      if (organization)
        $router.push('/organizationAdmin/' + id)
    }

    const statuses = ref([
      {
        value: 'active',
        text: 'Active'
      }, {
        value: 'inactive',
        text: 'Inactive'
      }
    ]);

    const status = ref(['active', 'inactive'])
    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'FirstName',
          value: 'firstName',
          filterable: true
        },
        {
          text: 'LastName',
          value: 'lastName',
          filterable: true
        },
        {
          text: 'Email',
          value: 'email',
          filterable: true
        },
        {
          text: 'Organization',
          value: 'organizationId',
        },
        {
          text: 'Phone',
          value: 'phone',
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      [
        'organization.name'
      ],
      true
    );
    const filter = computed(() => {
      return {
        $eager: '[role, organization]',
        status: { $in: status.value },
        roleId: { $in: [5] }
      }
    });
    const {
      items: users,
      isPending,
      pagination,
      findItems: findUsers,
      options,
      search
    } = useDataTableFind(
      'users',
      filterableColumns,
      filter,
      { sortBy: ['firstName'] }
    )

    const openSuperAdminExport = async (organizationId) => {
      await $dialog.showAndWait(
        SuperAdminExportDialog,
        {
          showClose: false,
          organizationId
        }
      )
    }

    return {
      users,
      isPending,
      headers,
      pagination,
      options,
      editingUser,
      findUsers,
      search,
      statuses,
      status,
      isOrgAdminCreateEditOpen,
      isConfirmDialogOpen,

      goToOrganization,
      openSuperAdminExport,

    }
  }
}
</script>

<style>

</style>