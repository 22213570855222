<template lang="pug">
.org-admin-create-edit-dialog
  CreateEditDialog(
    title="Add Org Admins"
    :item="newUser"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveOrgAdmin"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      .d-flex.gap-8
        v-text-field(
          outlined
          dense
          :rules="[rules.required]"
          label="Firstname"
          v-model="clone.firstName"
        )

        v-text-field(
          outlined
          dense
          :rules="[rules.required]"
          label="Lastname"
          v-model="clone.lastName"
        )

      v-text-field(
        outlined
        dense
        :rules="[rules.required, rules.email]"
        label="Email"
        v-model="clone.email"
      )

      v-text-field(
        outlined
        dense
        :rules="[rules.phone]"
        label="Phone"
        v-model="clone.phone"
      )

      StateList(
        v-model="clone.stateId"
      )

</template>

<script>
import StateList from '@/components/StateList'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
import formValidations from '../../helpers/formValidations'

export default {
  name: 'OrgUserCreateEditDialog',

  props: {
    title: {
      type: String,
      default: 'Add Org Admin'
    },
    isOpen: Boolean,
    editingUser: Object
  },

  components: {
    StateList,
    CreateEditDialog
  },

  methods: {
    async saveOrgAdmin({ save, reset }) {
      try {
        await save()
        this.$snackSuccess('Successfully added org admin user')
        this.$emit('close', true)
      } catch (e) {
        this.$snackError(e)
      } finally {
        reset()
      }
    }
  },

  setup(props, { root }) {
    const { valid, rules } = formValidations()
    const { User } = root.$FeathersVuex.api
    const orgUserDefaults = {
      group: 'organization',
      status: 'active',
      roleId: 5,
      stateId: 1,
      limitBy: 'orgType',
      limitById: '[]'
    }
    const newUser = new User(props.editingUser || orgUserDefaults)

    return {
      rules,
      valid,

      newUser
    }
  }
}
</script>

<style>

</style>