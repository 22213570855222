var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"organization-admin-list"},[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('div',{staticClass:"text-gray-900 text-2xl"},[_vm._v("Organization Admins")]),_c('div',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.isOrgAdminCreateEditOpen = true}}},[_vm._v("+ New Admin")])],1)]),_c('div',{staticClass:"d-flex justify-between mt-16 gap-8"},[_c('div',{staticClass:"flex-1"},[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"flex-1"},[_c('v-select',{attrs:{"outlined":"","multiple":"","dense":"","items":_vm.statuses},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('v-data-table',{staticClass:"border shadow cursor-pointer",attrs:{"must-sort":"","items":_vm.users,"loading":_vm.isPending,"server-items-length":!_vm.isPending && _vm.pagination ? _vm.pagination.total : 0,"headers":_vm.headers,"options":_vm.options,"no-data-text":"No organizations found"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToOrganization},scopedSlots:_vm._u([{key:"item.organizationId",fn:function(ref){
var user = ref.item;
return [_c('div',[_vm._v(_vm._s(user.organization ? user.organization.name : 'Not set up'))])]}},{key:"item.status",fn:function(ref){
var user = ref.item;
return [_c('v-chip',{attrs:{"color":((user.statusColor) + " dark-3"),"text-color":((user.statusColor) + " lighten-5"),"small":""}},[_c('span',[_vm._v(_vm._s(user.status))])])]}},{key:"item.actions",fn:function(ref){
var user = ref.item;
return [_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"gray","depressed":"","small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"divide-y"},[_c('v-list-item',{staticClass:"hover:bg-gray-200 cursor-pointer",on:{"click":function($event){return _vm.openDialog(user, 'orgAdmin')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-square-edit-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"cursor-pointer"},[_vm._v("Edit")])],1)],1),(_vm.$can('manage', 'all'))?_c('v-list-item',{staticClass:"hover:bg-gray-200 cursor-pointer",on:{"click":function($event){return _vm.openSuperAdminExport(user.organizationId)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"cursor-pointer"},[_vm._v("Export")])],1)],1):_vm._e(),(user.status === 'active' || user.status === 'new')?_c('v-list-item',{staticClass:"hover:bg-gray-200 cursor-pointer",on:{"click":function($event){return _vm.openDialog(user, 'confirmDeactivate')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-cancel")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"cursor-pointer"},[_vm._v("Deactivate")])],1)],1):_vm._e()],1)],1)]}}])}),(_vm.isOrgAdminCreateEditOpen)?_c('OrgAdminCreateEditDialog',{attrs:{"isOpen":_vm.isOrgAdminCreateEditOpen,"editingUser":_vm.editingUser},on:{"close":_vm.closeAndRefresh}}):_vm._e(),(_vm.isConfirmDialogOpen)?_c('ConfirmDialog',{attrs:{"title":_vm.confirmContext.title,"text":_vm.confirmContext.text,"actionText":_vm.confirmContext.actionText,"type":_vm.confirmContext.type,"isOpen":_vm.isConfirmDialogOpen},on:{"close":_vm.closeAndMaybeUpdateUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }