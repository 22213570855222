<template lang="pug">
v-card
  v-card-title.bg-white.border-b.py-2
    div Organization Export
    v-spacer
    div
      v-btn(
        fab
        small
        depressed
        @click="$emit('submit', null)"
      )
        v-icon mdi-close
  v-card-text
    v-textarea.mt-8(
      dense
      rows="2"
      auto-grow
      outlined
      label="Export email"
      v-model="exportEmails"
      persistent-hint
      hint="To send to multiple emails, separate each one by a comma"
    )

    .my-6.space-y-4
      .d-flex.align-center( :key="item.model" v-for="item of exportModels" )
        v-checkbox.my-0.py-0(
          hide-details
          v-model="selected"
          :label="item.model"
          :value="item.model"
        )
        .ml-4 ({{ item.count }} Records)

  v-card-actions
    v-btn(
      depressed
      @click="$emit('submit', null)"
      color="grey"
      outlined
    ) Cancel
    v-btn(
      :disabled="selected.length < 1"
      depressed
      @click="run()"
      color="primary"
    ) Export

</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useCount from '@/api/useCount'

export default {
  name: 'SuperAdminExportDialog',

  props: ['organizationId'],

  setup (props, { emit, root: { $FeathersVuex, $snackError, $snackSuccess, $store } }) {
    const { email } = $store.getters['auth/user'];
    const { Expense, Receipt, Export } = $FeathersVuex.api
    const selected = ref(['Expenses', 'Receipts'])
    const exportEmails = ref(email)
    const organizationId = ref(props.organizationId)

    const { total: expenseTotal } = useCount({
      model: Expense,
      params: {
        query: { organizationId: organizationId.value }
      }
    })
    const { total: receiptTotal } = useCount({
      model: Receipt,
      params: {
        query: { organizationId: organizationId.value }
      }
    })

    const exportModels = computed(() => {
      const exportModels = ref([
        { model: 'Expenses', count: expenseTotal.value },
        { model: 'Receipts', count: receiptTotal.value }
      ])
      return exportModels.value
    })

    const run = async () => {
      try {
        const services = []

        if (selected.value.includes('Expenses')) {
          const joins = { $eager: '[vendor, candidate(stateInclude), campaign]' }
          services.push(['expenses', joins])
        }

        if (selected.value.includes('Receipts')) {
          const joins = { $joinEager: '[state, organization]' }
          services.push(['receipts', joins])
        }

        const params = {
          // backend adds the orgId
          delivery: 'email',
          organizationId,
          emails: exportEmails.value,
          services,
          permission: 'superadmin'
        }
        const exportModel = new Export(params)
        await exportModel.create(params)
        $snackSuccess('Export emailed')
        emit('submit', null)
      } catch(e) {
        console.log('ERROR: ', e, e.message)
        $snackError(e.message)
      }
    }

    return {
      selected,
      exportModels,
      exportEmails,

      run
    }
  }
}
</script>
