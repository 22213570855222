import {
  reactive,
  toRefs,
  watch
} from '@vue/composition-api'

export default function find(options) {
  const defaults = { model: null }

  const getParams = () => {
    const { query } = options.params;

    return {
      query: { $limit: 0, ...query }
    }
  }

  const { model } = Object.assign(
    {},
    defaults,
    options
  )

  if (!model) {
    throw new Error(
      `No model provided for useApiFind(). Did you define and register it with FeathersVuex?`
    )
  }

  const state = reactive({
    isPending: false,
    total: 0
  });
 
  function count() {
    const params = getParams()
    state.isPending = true;

    return model
      .find(params)
      .then(response => {
        state.isPending = false
        state.total = response.total
        return response.total
      });
  }

  watch(
    () => getParams(),
    () => { count() },
    { immediate: true }
  )

  return {
    ...toRefs(state),
    count
  }
}